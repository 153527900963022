import ".././styles/AboutUs.css";
import ".././styles/CodeOfEthics.css";
function CodeOfEthics () {
    return(
        <div class="mission-body-text" id="code-of-ethics-text">
            <p>
            As a non-profit organization, we maintain our respect for communities and responsibility for all our decisions and its consequences. 
            These Codes of Ethical behaviours are intended to build respect, credibility, and strategic importance for the Philippine Canadian Centre of Manitoba Inc and the communities in which its serves.
            As such, we are committed to:
            </p>
            <ul id="ethics-list">
                <li><p>Acting honestly, truthfully, and with integrity in all our transactions and dealings;</p></li>
                <li><p>Avoiding conflicts of interest;</p></li>
                <li><p>Treating every individual and fellow member with dignity and respect;</p></li>
                <li><p>Treating our employees and volunteers with respect, fairness, and good faith and providing conditions of employment or volunteerism that safeguard their rights and well-being;</p></li>
                <li><p>Being responsible, transparent, and accountable for all our actions</p></li>
                <li><p>Adhere to and advocate the use of current policies on conflicts of interest within the organization;</p></li>
                <li><p>Comply with the rules of law and work or volunteer consistent with the values of the organization;</p></li>
                <li><p>Refrain from using our position as members of the governing board for personal, or political or financial gain or the appearance of such;</p></li>
                <li>Refrain from giving or seeking preferential treatment in all human and financial resources processes; AND</li>
                <li><p>Prioritize our obligations to identify conflicts of interest or the appearance thereof and when conflicts arise - disclose them immediately to fellow members of the governing board and relevant stakeholders.</p></li>
            </ul>
        </div>
    );
}

export default CodeOfEthics;