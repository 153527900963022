import ".././styles/AboutUs.css";
import ".././styles/CoreValues.css";

function CoreValues () {
  
    return(
        <div class="mission-body-text">
            <h3>Integrity and Accountability</h3>
            <p>
                We strive to be effectove in achieving our role and accountability to our members and stakeholders - the community,
                constituents, donors, board, staff, volunteers, collaborators, and the government. We believe in modeling this trustworthiness
                and integrity that earns continuously the public's high regard as a non-profit organization.
            </p>
            <h3>Service and Responisble Stewardship</h3>
            <p>
                We are committed to a strong ethic of timely and reliable service to the community and we strive to create
                an environment in which other organizations feel heard, safe, respected, amd supported. More importantly,
                we are committed to careful stewardship of all human, natural, financial resources and carefully use the time and talents of all members
                of our governing board and volunteers, and by wisely spending funds the accrue the organization.
            </p>
        </div>
    );
}

export default CoreValues;