import NewsScript from ".././pages/NewsScript.js";
import IframeResizer from "@iframe-resizer/react";
import ".././styles/NewsAnnouncements.css";
import { Helmet } from "react-helmet";



function NewsAnnouncements() {
    return (


        <>
            <div class="container">
                <div class="news-banner-container">
                    <div class="news-banner">
                        <h1>News and Announcments</h1>
                    </div>

                </div>
            </div>
            <div id="pccm-newsletter">
                <rssapp-wall id="3wm3vNMLrHdIR3SH"></rssapp-wall>
            </div>
            
        </>
    );
}

export default NewsAnnouncements;