import GROUP from ".././assets/boardmembers/BANNER.jpg";
import ".././styles/AboutUs.css";
import CoreValues from "../components/CoreValues";
import CodeOfEthics from "../components/CodeOfEthics";
import BoardmemberCard from "../components/BoardmemberCard";
import React, { useState } from "react";
import VIRGIE from ".././assets/boardmembers/VIRGIE.jpg";
import RODGE from ".././assets/boardmembers/RODGE.jpg";
import LOU from ".././assets/boardmembers/LOU.jpg";
import GERARD from ".././assets/boardmembers/GERARD.jpg";
import EFREN from ".././assets/boardmembers/ATTY.-EFREN.jpg";
import CHRIS from ".././assets/boardmembers/CHRIS.jpg";
import DANTE from ".././assets/boardmembers/DANTE.jpg";
import ENRICO from ".././assets/boardmembers/ENRICO.jpg";
import ERIC from ".././assets/boardmembers/ERIC.jpg";
import GIGI from ".././assets/boardmembers/GIGI.jpg";
import LUCILLE from ".././assets/boardmembers/LUCILLE.jpg";
import MARIA from ".././assets/boardmembers/MARIA.jpg";
import PAUL from ".././assets/boardmembers/PAUL.jpg";

import AboutScript from "./AboutScript";

function AboutUs() {
    
    const [active, setActive] = React.useState(1);
    const SetView = (active) => {
        setActive(active);
    };

    const ActiveView = () => {
        switch (active) {
            case 1:
                return <CoreValues />;
            default:
                return <CodeOfEthics />;
        }
    };

   
    return (
        <div>
            <div class="container">
                <div class="aboutus-banner-container">
                    <div class="aboutus-banner">
                        <h1>About Us</h1>
                    </div>
                </div>
                <div class="container">
                    <div class="aboutus-details">
                        <div id="aboutus-left">
                            <h3>Since the 1990s</h3>
                            <h2>
                                the PCCM is a charitable
                                non-profit Filipino-Canadian organization
                            </h2>
                        </div>
                        <div id="aboutus-story">
                            <h3>Our Story</h3>
                            <p>The Philippine Canadian Centre of Manitoba (PCCM) traces its origins to the late 1980s when the Filipino-Canadian 
                                community in Winnipeg embarked on a journey to establish a larger cultural hub. Initially housed in a modest facility on 
                                McDermot Avenue, discussions between community leaders and city officials in 1989 led to the proposal for a dedicated Philippine Cultural Centre. 
                                Negotiations with the City of Winnipeg culminated in a 50-year lease agreement for a 2.96-acre plot at a nominal cost, despite adjustments to the project scope due to logistical challenges.
                                Following these negotiations, the Philippine Centre Fundraising Committee was formed, later evolving into the Philippine Foundation of Manitoba, Inc., and ultimately incorporating as the Philippine Canadian Centre of Manitoba, 
                                Inc. in November 1998. Since its inception, PCCM has been a cornerstone of cultural identity and community pride, celebrating 25 years of enriching Filipino-Canadian heritage and serving as a vibrant hub for cultural, 
                                social, and educational activities in Winnipeg.
                            </p>
                        </div>
                    </div>
                </div>
                <div class="container" id="our-mission-container">
                    <div class="our-mission">
                        <h2>Our Mission</h2>
                        <p id="mission-description">
                            The Philippine Canadian Centre of Manitoba, Inc (PCCM) supports and/or defends core values in
                            our work and promotes them as we continue serving our members, the Filipino-Canadian community
                            and beyond.
                            We seek to nurture the spirit of a non-profit organization by recognizing and celebrating success and learning from valuable failures
                        </p>
                        <div class="mission-selector">
                            <input type="radio" name="answer"id="core-values-button" onClick={() => {SetView(1)}} defaultChecked/> <label for="core-values-button"><h3>Core Values</h3></label>
                            <input type="radio" name="answer"id="code-of-ethics-button" onClick={() => SetView(2)}/><label for="code-of-ethics-button"><h3>Code of Ethics</h3></label>
                        </div>
                        {ActiveView()}
                    </div>
                </div>
                <div class="container" id="boardmembers">
                    <h2>Boardmembers</h2>
                    <div id="boardmembers-list">
                        <BoardmemberCard
                            name="Virgie Gayot"
                            position="President"
                            description="Virgie has been the president of the PCCM since June 13, 2019.
                                    She graduated with a Bachelor of Science in Civil Engineering, St. Louis University in Baguio City, Philippines."
                            image={VIRGIE}
                        />
                        <BoardmemberCard
                            name="Gerard Madarang"
                            position="Vice President"
                            description="Gerard joined the PCCM in 2021. Prior to immigrating to Canada in 2016, 
                                    he served as a public health physician for nine years in the Philippines."
                            image={GERARD}
                        />
                        <BoardmemberCard
                            name="Rodrigo Lopez"
                            position="Secretary"
                            description="Rodrigo [Rodge] has been with the PCCM since 2014.
                                        Rodge volunteers at various community events and organizations."
                            image={RODGE}
                        />
                        <BoardmemberCard
                            name="Lou Fernandez"
                            position="Treasurer"
                            description="Lou served as PCCM president from 2014-2016. He has a bachelor of commerce degree and a graduate degree of master’s in business administration."
                            image={LOU}
                        />
                        <BoardmemberCard
                            name="Maria Harper"
                            position="Assistant Treasurer"
                            description="Maria is a Human Resource Professional in the Child & Family Services sector for the Government of Manitoba."
                            image={MARIA}
                        />
                        <BoardmemberCard
                            name="Dante Aviso"
                            position="Auditor"
                            description="Dante joined the PCCM board in 2021 and heads the fundraising, programs and services committee of PCCM and he is the artistic director of the Musica Singers of PCCM."
                            image={DANTE}
                        />
                        <BoardmemberCard
                            name="Atty. Efren Cabrera"
                            position="Member"
                            description="PCCM appointed him as a member of the Board of Directors in 2022. Efren is a practicing lawyer at Cabrera Law Firm."
                            image={EFREN}
                        />
                        <BoardmemberCard
                            name="Gigi Gacula"
                            position="Member"
                            description="Gigi immigrated to Canada in 2014. She is thrilled and happy to be a part of PCCM since 2018."
                            image={GIGI}
                        />
                        <BoardmemberCard
                            name="Eric Hernandez"
                            position="Member"
                            description="Eric has been a PCCM volunteer for the last six years. He heads the PCCM Building and Maintenance committee."
                            image={ERIC}
                        />
                        <BoardmemberCard
                            name="Lucille Nolasco-Garrido"
                            position="Member"
                            description="Lucille is a familiar voice in the community, being the host of 92.7 FM – CKJS drive-home program, Afternoon Pasada. She is also a Board Member of Pinays Manitoba"
                            image={LUCILLE}
                        />
                        <BoardmemberCard
                            name="Christopher Santos"
                            position="Member"
                            description="Chris was the PCCM President in 2018-2019. He has been an officer and volunteer for various community organizations."
                            image={CHRIS}
                        />
                        <BoardmemberCard
                            name="Enrico Tiglao"
                            position="Member"
                            description="Enrico joined the PCCM Board of Directors in 2018. He works as a site superintendent at J5 Construction."
                            image={ENRICO}
                        />
                        <BoardmemberCard
                            name="Paul Unciano"
                            position="Member"
                            description="Newly appointed member of the PCCM governing board, Paul is an accounting professional and a tech savvy finance person."
                            image={PAUL}
                        />
                    </div>
                </div>
                <div class="container" id="constitutions-bylaws-container">
                    <div id="constitutions-bylaws">
                        <h2>Constitution & By-Laws</h2>
                        <div id="preamble-section">
                            <h4>i. Preamble</h4>
                            <h5>1.1 THE CORPORATION</h5>
                                <p>
                                    The name of the corporation is the Philippine Canadian Centre of Manitoba, 
                                    Inc. which may
                                    also be known as PCCM or referred to as Corporation.
                                </p>
                            <h5>1.2 THE BY-LAWS</h5>
                                <p>The following articles set forth By-Laws of the Philippine Canadian 
                                Centre of Manitoba, Inc.
                                </p>
                        </div>
                        <div id="constitution-list">
                        <ul>
                            <div>
                                <li>→<p>Defintions & Interpretations</p></li>
                                <li>→<p>Members of the Corporation</p></li>
                                <li>→<p>Governing Board</p></li>
                                <li>→<p>Officers and their Accountabilities</p></li>
                            </div>
                            <div>
                                <li>→<p>Meetings of the Corporation</p></li>
                                <li>→<p>Committees</p></li>
                                <li>→<p>Borrowing Powers</p></li>
                                <li>→<p>Finances and Reports</p></li>
                            </div>
                            <div>
                                <li>→<p>Official Seal of the Corporation</p></li>
                                <li>→<p>Amendment and Dissolution</p></li>
                                <li>→<p>General Provisions</p></li>
                            </div>
                        </ul>
                        </div>
                    </div>
                </div>
                <div class="container">
                    <div class="google-reviews">
                        <AboutScript />
                        <iframe id="pccm-reviews"style={{border:'0', width:'100%', height: '100%'}} scrolling="no" width="100%" height="100%" src="https://embedsocial.com/api/pro_hashtag/9782cbd89dcff764e6eff33f8d982a7ed735a36e"></iframe>
                    </div>
                </div>
            </div>

        </div>
    );
}

export default AboutUs;