import ".././styles/Memberships.css";
import adminlogo from ".././assets/svgs/admin.svg";
import maintenencelogo from ".././assets/svgs/maintenance.svg";
import organizinglogo from ".././assets/svgs/calendar-19-svgrepo-com.svg";
import forumlogo from ".././assets/svgs/forums.svg";
import programslogo from ".././assets/svgs/programs.svg";
import campaignlogo from ".././assets/svgs/announce-svgrepo-com.svg";
function Memberships() {
    return (
        <>
            <div class="container">
                <div class="membership-banner-container">
                    <div class="membership-banner">
                        <h1>Memberships</h1>
                    </div>
                </div>
            </div>
            <div class="container">
                <div class="sub-container">
                    <div class="membership-section" id="membership-info">
                        <h2>Why Should You Become a PCCM Member?</h2>
                        <ul>
                            <li>
                                Membership is OPEN to individuals of Filipino descent and have permanent residency in Manitoba.
                            </li>
                            <li>
                                to show that you stand behind the mission, objectives, and core values of PCCM
                            </li>
                            <li>
                                to give you a voice in PCCM’s governance and as a member, you have a right to attend Annual Membership
                                Meetings, right to vote or be eligible to apply, nominated, and run for office as Member of the Governing
                                Board
                            </li>
                            <li>
                                to have the privilege of discounted use of the Boardroom or Multi-Purpose Room, and discounted cost of
                                tickets to community events that are sponsored by PCCM, and discounts from participating local businesses.
                            </li>
                        </ul>

                        <h3></h3>
                    </div>
                </div>
            </div>
            <div class="container" id="membership-categories-section">
                <div class="sub-container memberships">
                    <h2>Categories of Membership</h2>
                    <div class="membership-categories">
                        <div class="membership-category">
                            <img class="category-icon"src={adminlogo}></img>
                            <h3>Administrative Services</h3>
                        </div>
                        <div class="membership-category">
                            <img class="category-icon"src={campaignlogo}></img>
                            <h3>Membership Campaign</h3>
                        </div>
                        <div class="membership-category">
                            <img class="category-icon"src={maintenencelogo}></img>
                            <h3>Building Upkeep and Maintenance</h3>
                        </div>
                        <div class="membership-category">
                            <img class="category-icon"src={organizinglogo}></img>
                            <h3>Organizing Social and Fundraising Events</h3>
                        </div>
                        <div class="membership-category">
                            <img class="category-icon"src={programslogo}></img>
                            <h3>Programs and Services Development</h3>
                        </div>
                        <div class="membership-category">
                            <img class="category-icon"src={forumlogo}></img>
                            <h3>Organizing Forums and Information Sessions</h3>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}

export default Memberships;