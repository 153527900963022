import ".././styles/FeaturedEvents.css";
import EventCard from "../components/EventCard.js";
import featuredevent1 from ".././assets/featuredevent1.jpg";
import featuredevent2 from ".././assets/featuredevent2.jpg";
import featuredevent3 from ".././assets/featuredevent3.jpg";
import featuredevent4 from ".././assets/featuredevent4.jpg";
import useEmblaCarousel from "embla-carousel-react";
import React, { useEffect } from 'react';
import { Link } from "react-router-dom";
function FeaturedEvents() {
    // <script>
    //     GET https://www.googleapis.com/blogger/v3/blogs/4206628408241570047?key=AIzaSyDZP2fJat5Pt6yANzy9xrgb5wAxFYyYkVk
         
    // </script>
    // fetch("https://www.googleapis.com/blogger/v3/blogs/4206628408241570047?key=AIzaSyDZP2fJat5Pt6yANzy9xrgb5wAxFYyYkVk");
    async function getData() {
        const url = "https://www.googleapis.com/blogger/v3/blogs/4206628408241570047/posts?key=AIzaSyDZP2fJat5Pt6yANzy9xrgb5wAxFYyYkVk";
        try {
          const response = await fetch(url);
          if (!response.ok) {
            throw new Error(`Response status: ${response.status}`);
          }
      
          const json = await response.json();
          console.log(json.items[1]);
        } catch (error) {
          console.error(error.message);
        }
      }
      getData();

    const [emblaRef, emblaApi] = useEmblaCarousel({ loop: false },)
    useEffect(() => {
        if (emblaApi) {
            console.log(emblaApi.slideNodes()) // Access API
        }
    }, [emblaApi])
    return (
        
        <div class="container section">
            <div class="featured-events">
                <h2 class="section-title">
                    Past Events
                </h2>
                <div class="events-section">
                    <EventCard
                        image={featuredevent1}
                        title="lorem ipsum"
                        date="01/01/2024"
                        description="Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua."
                    />
                    <EventCard
                        image={featuredevent2}
                        title="lorem ipsum"
                        date="01/01/2024"
                        description="Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua."
                    />
                    <EventCard
                        image={featuredevent3}
                        title="lorem ipsum"
                        date="01/01/2024"
                        description="Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua."
                    />
                    <EventCard
                        image={featuredevent4}
                        title="lorem ipsum"
                        date="01/01/2024"
                        description="Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua."
                    /> 
                    
                </div>
                
                <div class="carousel-events">
                    <div class="embla-events" ref={emblaRef}>
                        <div class="embla-events-container">
                            <div class="embla-event-slide first-item">
                                <EventCard
                                    image={featuredevent1}
                                    title="lorem ipsum"
                                    date="01/01/2024"
                                    description="Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua."
                                />
                            </div>
                            <div class="embla-event-slide">
                                <EventCard
                                    image={featuredevent2}
                                    title="lorem ipsum"
                                    date="01/01/2024"
                                    description="Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua."
                                />
                            </div>
                            <div class="embla-event-slide">
                                <EventCard
                                    image={featuredevent3}
                                    title="lorem ipsum"
                                    date="01/01/2024"
                                    description="Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua."
                                />
                            </div>
                        </div>
                    </div>
                </div>
                <Link class="view-more events-view-more" to="/events">
                    View More
                </Link>
            </div>
        </div>
    );
}

export default FeaturedEvents;