import ".././styles/EventCard.css";

function EventCard({ image, title, date, description }) {
    return (

        <div class="event-card shadow2">
            <div class="event-img" style={{backgroundImage: `url(${image})`}}>
                {/* <img class="event-picture" src={image}></img> */}
            </div>
            <div class="event-details">
                <h3 class="event-title">
                    {title}
                </h3>
                <p class="event-date">
                    {date}
                </p>
                <p class="event-description">
                    {description}
                </p>
                <a class="learn-more">
                    Learn More
                </a>
            </div>
        </div>
    );
}

export default EventCard;