import ContactScript from "./ContactScript";
import ContactCard  from ".././components/ContactCard.js"
import ".././styles/Contact.css";
function Contact() {
    return (
        <div>
            <div class="container">
                <div class="contact-banner-container">
                    <div class="contact-banner">
                        <h1>Contact Us</h1>
                    </div>
                </div>
            </div>
                 <iframe id="contact-fields"src="https://forms.fillout.com/t/bjbiwRrAG5us" scrolling="no" width="100%" height="900" frameborder="0" marginheight="0" marginwidth="0">Loading…</iframe>
                    {/* <ContactCard /> */}
        </div>
    );
}

export default Contact;