import ".././styles/BoardmembersPreview.css";
import BoardmemberCard from "./BoardmemberCard";
import {Link} from "react-router-dom";
import VIRGIE from ".././assets/boardmembers/VIRGIE.jpg";
import GERARD from ".././assets/boardmembers/GERARD.jpg";
import RODGE from ".././assets/boardmembers/RODGE.jpg";
import LOU from ".././assets/boardmembers/LOU.jpg";

function BoardmembersPreview() {
    return (
        <div class="boardmembers-preview-container container section">
            <div class="boardmembers-preview wrapper">
                <h2 class="section-title">
                    Our Board Members
                </h2>
                <div class="container">
                <div class="boardmembers-section">
                    <BoardmemberCard
                        name="Virgie Gayot"
                        position="President"
                        description="Virgie has been the president of the PCCM since June 13, 2019.
                                    She graduated with a Bachelor of Science in Civil Engineering, St. Louis University in Baguio City, Philippines."
                        image={VIRGIE}
                    />
                    <BoardmemberCard
                        name="Gerard Madarang"
                        position="Vice President"
                        description="Gerard joined the PCCM in 2021. Prior to immigrating to Canada in 2016, 
                                    he served as a public health physician for nine years in the Philippines."
                        image={GERARD}
                    />
                    <BoardmemberCard
                        name="Rodrigo Lopez"
                        position="Secretary"
                        description="Rodrigo [Rodge] has been with the PCCM since 2014.
                                        Rodge volunteers at various community events and organizations."
                        image={RODGE}
                    />
                    <BoardmemberCard
                            name="Lou Fernandez"
                            position="Treasurer"
                            description="Virgie has been the president of the PCCM since June 13, 2019.
                                    She graduated with a Bachelor of Science in Civil Engineering, St. Louis University in Baguio City, Philippines."
                            image={LOU}
                        />
                </div>
                </div>
                <Link to="/aboutus" class="view-more board-members-view-more">View More</Link>
            </div>
        </div>
    );
}

export default BoardmembersPreview;