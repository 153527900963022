import ".././styles/Hero.css";
import heroimage from ".././assets/hero.jpg";
function Hero() {
    return(
        <div class="hero-container">
            <div class="hero-wrapper wrapper">
                <div class="hero">
                    <div class="hero-contents">
                        <h1 id="hero-primary-text">
                        The Philippine-Canadian Centre of Manitoba
                        </h1>
                        <p class="body-text" id="hero-text">
                        serves as an umbrella organization in keeping their traditions, customs, values, and identity, remain strong even far from the motherland.
                        </p>
                        <h3 class="button-2 button-shadow" id="hero-button">
                            Explore
                        </h3>  
                    </div>
                </div>
            </div>
        </div>
    );
}
export default Hero;