import advertisepic from ".././assets/adposter.png";
import ".././styles/styles.css";
import ".././styles/AdvertiseBanner.css";
function AdvertiseBanner() {
    return (
        <>
            <div class="container">
                <div id="advertise-banner">
                    
                    <div id="ad-details">
                        <div id="ad-text">
                        <h2>Want to Advertise with PCCM?</h2>
                            <h3>See poster for more details on how to work with us.</h3>
                            </div>
                    </div>
                    <div id="ad-img-container">
                        <img id="advertise-pic"src={advertisepic}></img>
                    </div>
                </div>
            </div>
        </>
    );
}

export default AdvertiseBanner;